import React from 'react'
import { NpmRepeatOrderPage } from '@plurix/ecom-pages'

import type { PageProps } from 'gatsby'
import type { OrderType } from '@plurix/ecom-pages/src/types/RepeatOrder'

function RepeatOrderPage({ location }: PageProps) {
  const params = new URLSearchParams(location.search)
  const orderId = params.get('orderId') ?? ''
  const type = (params.get('type') ?? '') as OrderType

  return <NpmRepeatOrderPage orderId={orderId} type={type} />
}

export default RepeatOrderPage
